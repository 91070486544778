var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-img", {
        staticClass: "mx-auto mt-12",
        attrs: {
          src: require("../../assets/caixaqui/marca_caixa_aqui.jpg"),
          width: "128",
          height: "127"
        }
      }),
      _c(
        "v-card",
        {
          staticClass: "mx-auto menu-caixaqui-card",
          attrs: { "max-width": "400", tile: "" }
        },
        [
          _c(
            "v-list",
            { staticClass: "menu-caixaqui-list" },
            _vm._l(_vm.items, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: i,
                  staticClass: "menu-caixaqui-list-item",
                  attrs: { to: item.to }
                },
                [
                  _c(
                    "v-list-item-content",
                    {
                      class: [
                        "menu-caixaqui-list-item-content",
                        { "btn-laranja": item.isOrange }
                      ]
                    },
                    [
                      _c("v-list-item-title", {
                        staticClass: "menu-caixaqui-list-item-title",
                        domProps: { textContent: _vm._s(item.text) }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }