<template>
  <CaixaquiMenu :items="menus"></CaixaquiMenu>
</template>

<script>
import CaixaquiMenu from "../../../components/caixaqui/CaixaquiMenu";
import ROUTERS from "../../../routes/router-constants";
import { mapGetters } from "vuex";

export default {
  name: "CaixaquiMenuPrincipal",
  components: {
    CaixaquiMenu
  },
  computed: {
    ...mapGetters("controller", ["sourceRoute"]),
    menus() {
      return [
        { text: "Serviços ao Cliente", to: { name: ROUTERS.caixaqui.menus.servicoCliente.name } },
        { text: "Alteração Senha de Acesso", to: "" },
        { text: "Relatórios", to: "" },
        { text: "Solicitações Diversas", to: "" },
        { text: "Sair", to: this.sourceRoute, isOrange: true }
      ];
    }
  }
};
</script>

<style scoped></style>
