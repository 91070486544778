<template>
  <v-container fluid>
    <v-img
      class="mx-auto mt-12"
      :src="require('../../assets/caixaqui/marca_caixa_aqui.jpg')"
      width="128"
      height="127"
    ></v-img>

    <v-card class="mx-auto menu-caixaqui-card" max-width="400" tile>
      <v-list class="menu-caixaqui-list">
        <v-list-item v-for="(item, i) in items" :key="i" class="menu-caixaqui-list-item" :to="item.to">
          <v-list-item-content :class="['menu-caixaqui-list-item-content', { 'btn-laranja': item.isOrange }]">
            <v-list-item-title class="menu-caixaqui-list-item-title" v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "CaixaquiMenu",
  props: ["items"]
};
</script>

<style scoped>
.menu-caixaqui-card {
  width: 304px;
  margin: 32px auto 0px;
  background: #cccccc;
  padding: 5px;
}

.menu-caixaqui-list {
  background: #cccccc;
  padding: 0;
}

.menu-caixaqui-list-item {
  width: 100%;
  line-height: 1.2;
  padding: 0 3px 0 2px;
  min-height: 28px;
}

.menu-caixaqui-list-item-content {
  background: linear-gradient(to bottom, #1f4780, #355d96);
  border: 1px solid #355d96;
  border-radius: 4px;
  color: #ffffff;
  padding: 7px 12px 3px;
}

.menu-caixaqui-list-item-content:hover {
  background-color: #141280;
}

.menu-caixaqui-list-item-title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 12px;
}

.btn-laranja {
  background: -moz-linear-gradient(center bottom, #ec801c, #ff9816) repeat scroll 0 0 rgba(0, 0, 0, 0);
  background: linear-gradient(to bottom, #ec801c, #ff9816);
  border: 1px solid #ec801c;
  border-radius: 4px;
}
</style>
